/* eslint-disable no-nested-ternary */
import React from 'react';
import { Icon } from '@savewatersavemoney/swsm-ui';
import { SelectControlProps } from '../../../interfaces';
import Styled from './SelectControl.style';
import { useTranslation } from 'react-i18next';

// can NOT be combined with others controls
// need interaction to proceed to next step
// need function set up answer
// need to call function to set visible next nav
// need to be able prepare view just by one answer key
// need to be able to hide next nav if not valid answer on not complete

const SelectControl: React.FC<SelectControlProps> = props => {
  const {
    size,
    options,
    answerKey,
    updateAnswerKey,
    value,
    required,
    validateRequiredKeys,
    setNavNextVisibility,
    defaultValue,
  } = props;
  const defaultText = options.find(el => el.value === defaultValue);
  const selectedText = options.find(el => el.value === value);
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const [t] = useTranslation('common');
  const renderTextsDefault = value
    ? selectedText?.text
    : defaultValue && defaultText && !value
      ? t(defaultText.text)
      : '';
  const [isVisible, setVisible] = React.useState(false);
  const [valueDrop, setValuedrop] = React.useState(renderTextsDefault);
  React.useEffect(() => {
    if (defaultValue) {
      setNavNextVisibility(true);
      updateAnswerKey(answerKey, defaultValue);
    }
  }, [answerKey, defaultValue, required, setNavNextVisibility, updateAnswerKey]);
  const handleChange = value => {
    updateAnswerKey(answerKey, value);

    if (required) {
      validateRequiredKeys();
    }
  };
  return (
    <Styled.Wraper
      size={size}
      onClick={() => {
        setVisible(!isVisible);
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
          background: valueDrop ? '#d7f1ff' : 'white',
        }}
      >
        <div style={{ flex: 1, display: 'flex' }}>
          <p style={{ padding: '16px 8px 16px 16px', margin: '0px', textAlign: 'left' }}>
            {t(props.desc)}
            {valueDrop && <span style={{ fontWeight: 400, fontSize: '14px' }}> - {t(valueDrop)}</span>}
          </p>
        </div>
        <div style={{ padding: '0px 16px', marginBottom: '8px' }}>
          <Icon icon="chevronDown" size={20} className={isVisible ? 'rotated' : ''} />
        </div>
      </div>
      <div
        ref={contentRef}
        style={{
          overflow: 'hidden',
          transition: 'max-height 0.2s ease-in-out',
          maxHeight: isVisible ? `${contentRef.current?.scrollHeight}px` : '0',
        }}
      >
        {options.map((el, index) => {
          return (
            <Styled.Item
              className={value === el.value ? 'active' : ''}
              onClick={() => {
                handleChange(el.value);
                setValuedrop(el.text);
              }}
              key={index}
            >
              {t(el.text)}
            </Styled.Item>
          );
        })}
      </div>
    </Styled.Wraper>
  );
};

export default SelectControl;
