import React from 'react';
import Styled from './MultiSelectDropdownControl.style';
import { MultiSelectDropdownControlProps } from '../../../interfaces';
import { Checkbox } from '@savewatersavemoney/swsm-ui';
import { useTranslation } from 'react-i18next';
const MultiSelectDropdownControl: React.FC<MultiSelectDropdownControlProps> = props => {
  const {
    answerKey,
    value,
    desc,
    text,
    required,
    setNavNextVisibility,
    validateRequiredKeys,
    options,
    dropdownValue,
    setDropdownKey,
    dropdownAnswerKey,
    updateAnswerKey = () => void 0,
  } = props;

  const [isVisible, setVisible] = React.useState(!!value);
  const defaultText =
    dropdownValue[dropdownAnswerKey] && options.find(el => el.value === dropdownValue[dropdownAnswerKey]);
  const dropdownText = dropdownValue[dropdownAnswerKey] ? defaultText?.option_text : '';
  const [valueDrop, setValuedrop] = React.useState(dropdownText ? dropdownText : '');
  const contentRef = React.useRef<HTMLDivElement | null>(null);
  const [isValue, setValue] = React.useState(
    dropdownValue[dropdownAnswerKey] ? `${dropdownValue[dropdownAnswerKey]}` : '',
  );

  const [t] = useTranslation('common');

  React.useEffect(() => {
    setNavNextVisibility(true);
    setVisible(false);
  }, [setNavNextVisibility]);

  const handleUpdate = event => {
    updateAnswerKey(answerKey, event.target.checked);

    if (!event.target.checked) {
      updateAnswerKey(dropdownAnswerKey, null);
      setValuedrop('');
      setVisible(false);
    }
    else {
      setVisible(true);
    }
    if (required) {
      validateRequiredKeys();
    }
  };

  const handleChange = value => {
    if (value) {
      updateAnswerKey(dropdownAnswerKey, value);
      setDropdownKey(dropdownAnswerKey, value);
      setValue(value);
      setVisible(false);
      setNavNextVisibility(true);
    }
    else {
      setValue('');
      setNavNextVisibility(false);
    }
  };

  return (
    <Styled.CheckboxControl>
      <div className="controls" style={{ backgroundColor: !!value ? '#d7f1ff' : '#fff' }}>
        <Checkbox
          style={{ padding: '16px', width: '100%', display: 'flex' }}
          boxSize={20}
          theme="rounded"
          type="checkbox"
          name={`GWF-${answerKey}`}
          id={`GWF-${answerKey}`}
          checked={!!value}
          onChange={event => {
            handleUpdate(event);
          }}
        >
          {text ? (
            <div style={{ fontSize: '16px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
              {text}{' '}
              {valueDrop && (
                <span style={{ marginLeft: '8px', fontSize: '14px', fontWeight: 400 }}> &#45; {t(valueDrop)}</span>
              )}
            </div>
          ) : (
            ''
          )}
          {desc === null ? (
            <div
              style={{
                fontSize: text ? '14px' : '18px',
                paddingTop: text ? 0 : '7px',
              }}
            >
              {desc}
            </div>
          ) : null}
        </Checkbox>
      </div>
      <div
        ref={contentRef}
        style={{
          overflow: 'hidden',
          transition: 'max-height 0.2s ease-in-out',
          maxHeight: isVisible ? `${contentRef.current?.scrollHeight}px` : '0',
        }}
      >
        {options.map(el => {
          return (
            <Styled.Item
              className={isValue === el.value ? 'active' : ''}
              key={el.option_text}
              onClick={() => {
                handleChange(el.value);
                setValuedrop(el.option_text);
              }}
            >
              {t(el.option_text)}
            </Styled.Item>
          );
        })}
      </div>
    </Styled.CheckboxControl>
  );
};

export default MultiSelectDropdownControl;
